'use client';

import { useContext, useRef, useState } from 'react';
import styles from './dragAble.module.scss';
import { useEventListener } from '@/scripts';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { __DragAblePropsType, __DragAbleSettingType } from './dragAble.type';
import { GlobalContext } from '@/context';
import { LanguageEnum } from '@/types';
function DragAbleComp({
  className,
  parentClassName,
  children,
  iconClassName
}: __DragAblePropsType) {
  const {
    langText
  } = useContext(GlobalContext);
  const parentRef = useRef<HTMLDivElement>(null);
  const dragAbleSetting = useRef<__DragAbleSettingType>({
    isMouseDown: false,
    velX: 0
  });
  const [scrolling, setScrolling] = useState(false);
  const beginMomentumTracking = () => {
    cancelMomentumTracking();
    dragAbleSetting.current = {
      ...dragAbleSetting.current,
      momentumID: requestAnimationFrame(momentumLoop)
    };
  };
  const cancelMomentumTracking = () => {
    if (dragAbleSetting.current.momentumID) cancelAnimationFrame(dragAbleSetting.current.momentumID);
  };
  const momentumLoop = () => {
    if (parentRef.current) parentRef.current.scrollLeft += dragAbleSetting.current.velX;
    dragAbleSetting.current = {
      ...dragAbleSetting.current,
      velX: 0.9 * dragAbleSetting.current.velX
    };
    if (Math.abs(dragAbleSetting.current.velX) > 0.5) {
      dragAbleSetting.current = {
        ...dragAbleSetting.current,
        momentumID: requestAnimationFrame(momentumLoop)
      };
    }
  };
  const onNextClick = () => {
    dragAbleSetting.current = {
      ...dragAbleSetting.current,
      velX: -40
    };
    beginMomentumTracking();
  };
  const onPrevClick = () => {
    dragAbleSetting.current = {
      ...dragAbleSetting.current,
      velX: 40
    };
    beginMomentumTracking();
  };
  useEventListener<MouseEvent>('mousedown', e => {
    dragAbleSetting.current = {
      ...dragAbleSetting.current,
      isMouseDown: true,
      startX: e.pageX - (parentRef.current?.offsetLeft ?? 0),
      scrollLeft: parentRef.current?.scrollLeft
    };
    setScrolling(true);
    cancelMomentumTracking();
  }, parentRef.current);
  useEventListener('mouseleave', () => {
    dragAbleSetting.current = {
      ...dragAbleSetting.current,
      isMouseDown: false
    };
    setScrolling(false);
  }, parentRef.current);
  useEventListener('mouseup', () => {
    dragAbleSetting.current = {
      ...dragAbleSetting.current,
      isMouseDown: false
    };
    beginMomentumTracking();
    setScrolling(false);
  }, parentRef.current);
  useEventListener<MouseEvent>('mousemove', e => {
    if (dragAbleSetting.current.isMouseDown && parentRef.current) {
      e.preventDefault();
      const x = e.pageX - parentRef.current.offsetLeft;
      const walk = x - (dragAbleSetting.current.startX ?? 0);
      const prevScrollLeft = parentRef.current.scrollLeft;
      parentRef.current.scrollLeft = (dragAbleSetting.current.scrollLeft ?? 0) - walk;
      dragAbleSetting.current = {
        ...dragAbleSetting.current,
        velX: parentRef.current.scrollLeft - prevScrollLeft
      };
    }
  }, parentRef.current);
  const LeftArrow = langText.lang === LanguageEnum.fa ? TbArrowLeft : TbArrowRight;
  const RightArrow = langText.lang === LanguageEnum.en ? TbArrowLeft : TbArrowRight;
  return <div className={`${styles['drag-able-scroll']} ${scrolling ? styles['scrolling'] : ''} 
            ${(Array.isArray(children) ? !children.length : !children) ? styles['hide'] : ''} 
            ${parentClassName ?? ''} align-items-center w-100`} data-sentry-component="DragAbleComp" data-sentry-source-file="dragAble.index.tsx">
            <div ref={parentRef} className={`${className ?? ''} w-100`}>
                {children}
            </div>
            <LeftArrow onClick={onNextClick} size={20} className={`pointer d-pre-md-none ${iconClassName ?? ''} ${styles['scroll-icon']} ${styles['left']}`} data-sentry-element="LeftArrow" data-sentry-source-file="dragAble.index.tsx" />
            <RightArrow onClick={onPrevClick} size={20} className={`pointer d-pre-md-none ${iconClassName ?? ''} ${styles['scroll-icon']} ${styles['right']}`} data-sentry-element="RightArrow" data-sentry-source-file="dragAble.index.tsx" />
        </div>;
}
export default DragAbleComp;