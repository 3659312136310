'use client';

import styles from './landing.module.scss';
import Image from 'next/image';
import bgLanding from 'assets/images/landing2/bg-landing.svg';
import bgLandingMobile from 'assets/images/landing2/bg-landing-mobile.svg';
import bgSVG from 'assets/images/landing2/svg1.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from 'context';
import { CategoryType, ServiceType, PaginationType, ServiceTypeEnum, FAQType, HouseType } from 'types';
import FAQComp from './FAQ/FAQ.index';
import { __HeaderList, __LandingData } from './landing.data';
import { COLORS } from 'utilities';
import { ServiceNavigationComp, BottomNavigationComp } from 'components';
import { nextBrowser } from 'scripts';
import { PATH } from 'data';
import AnimatedTextComp from './animatedText/animatedText.index';
import LandingServiceContentComp from './serviceContent/serviceContent.index';
import { useRouter, useSearchParams } from 'next/navigation';
import HintComp from './hint/hint.index';
import BestsComp from './bests/bests.index';
import servicesSVG from 'assets/images/landing2/servicesSVG.svg';
function LandingComp(props: {
  categories?: CategoryType[];
  services?: PaginationType<ServiceType>;
  serviceType?: string;
  faq: FAQType[];
  houses?: PaginationType<HouseType>;
}) {
  const router = useRouter();
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  const [isScroll, setIsScroll] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>();
  const searchParams = useSearchParams();
  const getActiveTab = (newItem?: string) => {
    const service = newItem ?? props.serviceType;
    if (service === ServiceTypeEnum.offer) return ServiceTypeEnum.offer;
    if (service === ServiceTypeEnum.dating) return ServiceTypeEnum.dating;
    if (service === ServiceTypeEnum.housing) return ServiceTypeEnum.housing;
    if (service === ServiceTypeEnum.events) return ServiceTypeEnum.events;
    if (service === ServiceTypeEnum.hiring) return ServiceTypeEnum.hiring;
    return ServiceTypeEnum.offer;
  };
  const [activeIndex, setActiveIndex] = useState(getActiveTab());
  const onServiceClick = (item: ServiceTypeEnum) => {
    router.replace(PATH(langText.lang).home(item));
    // setActiveIndex(item);
    // nextBrowser.window?.history.replaceState(null, '', PATH(langText.lang).home(item));
    // nextBrowser.window?.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    const item = searchParams.get('service');
    if (item) {
      setActiveIndex(s => {
        if (s !== item) nextBrowser.window?.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        return getActiveTab(item);
      });
    }
    const descriptionMetaTag: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');
    if (descriptionMetaTag) descriptionMetaTag.href = PATH(langText.lang).home(Object.values(ServiceTypeEnum).find(i => i === item) ?? ServiceTypeEnum.offer);
  }, [searchParams]);
  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsScroll(!entry.isIntersecting));
  }, []);
  useEffect(() => {
    if (boxRef.current) observerRef.current?.observe(boxRef.current);
    return () => {
      observerRef.current?.disconnect();
    };
  }, [boxRef.current]);
  return <div className={`${styles['container']}`} data-sentry-component="LandingComp" data-sentry-source-file="landing.index.tsx">
            <div className={`${styles['top-section-layout']}`}>
                <div className={`${styles['image']}`}>
                    <img src={bgLanding.src} alt="landing" className="d-pre-md-none" />
                    <img src={bgLandingMobile.src} width={600} height={544} alt="landing" className="d-md-none" />
                </div>
                <div className={`${styles['title-layout']} center-content-x`}>
                    <div className={`${styles['navigation-box']}`} ref={boxRef}>
                        <AnimatedTextComp activeIndex={activeIndex} className="d-md-none" data-sentry-element="AnimatedTextComp" data-sentry-source-file="landing.index.tsx" />
                        <ServiceNavigationComp activeIndex={activeIndex} setActiveIndex={onServiceClick} className={`${styles['navigation']} ph-2-i pb-2-i`} data-sentry-element="ServiceNavigationComp" data-sentry-source-file="landing.index.tsx" />
                    </div>
                </div>
            </div>
            <div className={`${styles['slider-container']} main-content`}>
                <Image src={bgSVG} alt="svg" width={1440} height={544} data-sentry-element="Image" data-sentry-source-file="landing.index.tsx" />
                <div className={`${styles['slider-layout']}`}>
                    <div className={`${styles['header']} d-pre-md-none`}>
                        <ul className="p-0-i m-0-i">
                            {__HeaderList(lang).map(item => <li key={item.id} onClick={() => onServiceClick(item.id)} className={`center-content gap-8 pointer ${item.id === activeIndex ? styles['active'] : ''} `}>
                                    <item.icon size={24} color={item.id === activeIndex ? COLORS.primary : COLORS.dark3} />
                                    <p className={`d-pre-md-none t-h4 t-500 t-dark2`}>{item.title}</p>
                                </li>)}
                        </ul>
                    </div>
                    <AnimatedTextComp activeIndex={activeIndex} className="pt-4 ph-2 d-pre-md-none" data-sentry-element="AnimatedTextComp" data-sentry-source-file="landing.index.tsx" />
                    <HintComp active={activeIndex} data-sentry-element="HintComp" data-sentry-source-file="landing.index.tsx" />
                    <LandingServiceContentComp categories={props.categories} services={props.services} activeIndex={activeIndex} houses={props.houses} data-sentry-element="LandingServiceContentComp" data-sentry-source-file="landing.index.tsx" />
                </div>
            </div>
            <BestsComp categories={props.categories} data-sentry-element="BestsComp" data-sentry-source-file="landing.index.tsx" />
            <div className={`${styles['services-container']} main-content`}>
                <img src={servicesSVG.src} alt="landing" width={979} height={924} className={`${styles['services-svg']}`} />
                <h2 className="t-h2 t-800 t-dark1 mb-6">{lang.serviceTitle}</h2>
                <div className={`${styles['services-layout']}`}>
                    {__LandingData(lang).map(item => <div key={item.id} className={`${styles['service-card']} d-flex align-items-center gap-24`}>
                            <span className="d-flex center-content">
                                <Image src={item.src} alt="service" width={item.size} height={item.size} />
                            </span>
                            <div>
                                <p className="t-p1 t-700 t-dark1 mb-2">{item.title}</p>
                                <p className="t-p2 t-400 t-dark3 d-pre-md-none">{item.desc}</p>
                                <p className="t-p2 t-400 t-primary d-none d-pre-md-block pointer">{lang.seeMore}</p>
                            </div>
                        </div>)}
                </div>
            </div>
            <FAQComp className={`${styles['faq']}`} data={props.faq} data-sentry-element="FAQComp" data-sentry-source-file="landing.index.tsx" />
            <BottomNavigationComp className={`${!isScroll ? styles['hidden'] : ''}`} activeIndex={activeIndex} setActiveIndex={onServiceClick} data-sentry-element="BottomNavigationComp" data-sentry-source-file="landing.index.tsx" />
        </div>;
}
export default LandingComp;