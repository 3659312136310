'use client';

import { GlobalContext } from '@/context';
import styles from './datingCard.module.scss';
import Image, { StaticImageData } from 'next/image';
import { useContext } from 'react';
import { MBTIEnum } from 'types';
import { MBTIPersonalities } from '@/data';
function DatingCardComp(props: {
  item: {
    avatar: StaticImageData;
    gender: string;
    mbti: MBTIEnum;
    name: string;
    age: number;
    location: string;
  };
  className?: string;
}) {
  const {
    langText
  } = useContext(GlobalContext);
  return <div className={`${props.className ?? ''} ${styles['dating-card']}`} data-sentry-component="DatingCardComp" data-sentry-source-file="datingCard.index.tsx">
            <Image src={props.item.avatar} width={260} alt={props.item.name} data-sentry-element="Image" data-sentry-source-file="datingCard.index.tsx" />
            <div>
                <h6 className="t-h4 t-white">
                    <span>{props.item.name}</span>
                    <span>{' , '}</span>
                    <span>{props.item.age}</span>
                    <span>{langText.landing2.yearsOld}</span>
                </h6>
                <p className="t-p4 t-white">{props.item.location}</p>
            </div>
            <span className={props.item.gender === 'male' ? styles.male : styles.female}>
                {MBTIPersonalities(langText.dating.mbti).find(i => i.value === props.item.mbti)?.phrase}
            </span>
        </div>;
}
export default DatingCardComp;