import { GlobalContext } from '@/context';
import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from '../landing.module.scss';
import line from 'assets/images/landing2/line.svg';
import { ServiceTypeEnum } from '@/types';
const AnimatedTextComp = (props: {
  activeIndex: ServiceTypeEnum;
  className?: string;
}) => {
  const lang = useContext(GlobalContext).langText.landing2;
  const delay = 40; // milliseconds
  const [text, setText] = useState<{
    text: string;
    index: number;
    id: ServiceTypeEnum;
    status: 'done' | 'decreasing' | 'increasing';
  }>({
    text: lang.title1,
    index: lang.title1.length,
    id: props.activeIndex,
    status: 'done'
  });
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const changeTab = () => {
    timeout.current = setTimeout(() => {
      const id = props.activeIndex;
      const newText = id === ServiceTypeEnum.offer ? lang.title1 : id === ServiceTypeEnum.dating ? lang.title2 : id === ServiceTypeEnum.housing ? lang.title3 : id === ServiceTypeEnum.events ? lang.title4 : id === ServiceTypeEnum.hiring ? lang.title5 : '';
      setText(prevText => {
        if (prevText.status === 'done' && id !== prevText.id || prevText.status === 'decreasing' && prevText.index > 0) {
          return {
            text: prevText.text.slice(0, prevText.index - 1),
            status: 'decreasing',
            index: prevText.index - 2 > 1 ? prevText.index - 2 : 0,
            id: prevText.id
          };
        } else if (prevText.status === 'decreasing' && prevText.index === 0 || prevText.status === 'increasing' && prevText.index < newText.length) {
          return {
            text: newText.slice(0, prevText.index + 1),
            status: 'increasing',
            index: prevText.index + 1,
            id
          };
        } else return {
          text: newText,
          status: 'done',
          index: newText.length,
          id
        };
      });
    }, delay);
  };
  useEffect(() => changeTab(), [text.index, props.activeIndex]);
  return <h2 className={`t-h2 t-900 t-dark1 text-center ${props.className ?? ''} ${styles['animated-text']}`} data-sentry-component="AnimatedTextComp" data-sentry-source-file="animatedText.index.tsx">
            <Image className={styles['img-1']} src={line} width={16} height={17} alt="line" data-sentry-element="Image" data-sentry-source-file="animatedText.index.tsx" />
            <span className="t-h2 t-900">{lang.bestOpp}</span>{' '}
            <span className={`t-primary-i ${styles['animation']}`}>{text.text}</span>{' '}
            <span className="t-h2 t-900">{lang.inToronto}</span>
            <Image className={styles['img-2']} src={line} width={16} height={17} alt="line" data-sentry-element="Image" data-sentry-source-file="animatedText.index.tsx" />
        </h2>;
};
export default AnimatedTextComp;