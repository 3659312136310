'use client';

import { Input, Pagination } from 'antd';
import styles from './bests.module.scss';
import { CiSearch } from 'react-icons/ci';
import { COLORS } from '@/utilities';
import CategoryListComp from '../categoryList/categoryList.index';
import { CategoryType } from '@/types';
import { useContext, useState } from 'react';
import BestCardComp from './bestCard/bestCard.index';
import { FaStar } from 'react-icons/fa6';
import Image from 'next/image';
import bgSVG from 'assets/images/landing2/bestSVG.svg';
import { __DummyData } from '@/tehranto/landing/landing.data';
import { GlobalContext } from '@/context';
function BestsComp({
  categories
}: {
  categories?: CategoryType[];
}) {
  const [slug, setSlug] = useState<Partial<CategoryType>>();
  const bestBrands = __DummyData['BestBrands'];
  const langText = useContext(GlobalContext).langText.bests;
  return <div className={`${styles['bests-container']} main-content`} data-sentry-component="BestsComp" data-sentry-source-file="bests.index.tsx">
            <div className={`${styles['bests-top-section']} center-content flex-column pv-8 ph-4 mb-8`}>
                <Image src={bgSVG} width={1120} height={450} alt="svg" className={`${styles['best-svg']}`} data-sentry-element="Image" data-sentry-source-file="bests.index.tsx" />
                <div className={`${styles['stars']} center-content mb-3`}>
                    <FaStar color={COLORS.primary} size={26} style={{
          marginTop: '12px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={33} style={{
          marginTop: '6px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={40} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={33} style={{
          marginTop: '6px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={26} style={{
          marginTop: '12px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                </div>
                <h1 className="t-h1 t-900 mb-5">{langText.title}</h1>
                <p className="t-p1 t-400 t-dark3 mb-5 text-center">{langText.desc}</p>
                <Input suffix={<CiSearch size={18} color={COLORS.dark3} />} className={`${styles['search-input']}`} placeholder={langText.placeholder} data-sentry-element="Input" data-sentry-source-file="bests.index.tsx" />
            </div>
            {categories ? <CategoryListComp data={categories} onCategoryChange={item => setSlug(item)} slug={slug} /> : null}
            <div className={`${styles['bests-layout']} mt-5`}>
                {bestBrands.filter(item => item.ads).map(brand => <BestCardComp key={brand.id} data={brand} />)}
                <div className={`${styles['best']} mv-1 pt-2 pb-1 ph-1`}>
                    <h4 className="t-h4 t-700 mh-2 mb-2">{langText.bests}</h4>
                    {bestBrands.filter(item => !item.ads).splice(0, 5).map(brand => <BestCardComp key={brand.id} data={brand} best />)}
                </div>
                {bestBrands.filter(item => !item.ads).slice(5).map(brand => <BestCardComp key={brand.id} data={brand} />)}
            </div>
            <div className={`${styles['pagination-layout']} d-flex center-content mt-5 mb-10`}>
                <div className={`${styles['pagination']} d-flex center-content`}>
                    <Pagination size="small" pageSize={5} showSizeChanger={false} hideOnSinglePage total={20} data-sentry-element="Pagination" data-sentry-source-file="bests.index.tsx" />
                </div>
            </div>
        </div>;
}
export default BestsComp;