import { BPEnum, CategoryType, HouseType, LanguageEnum, PaginationType, ServiceType, ServiceTypeEnum, TenantType } from '@/types';
import styles from '../landing.module.scss';
import csSVG from 'assets/images/landing2/svg2.svg';
import csEnSVG from 'assets/images/landing2/comming_soon_en.png';
import CategoryListComp from '../categoryList/categoryList.index';
import { SliderWrapperComp, AppLinkComp, HouseCardComp, RoommateCardComp } from 'components';
import ServiceCardComp from '../serviceCard/serviceCard.index';
import { Button, Spin, message } from 'antd';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/context';
import { API, PATH } from '@/data';
import { RestAPI, useBP } from '@/scripts';
import Image from 'next/image';
import { __DatingFakeData } from '../landing.data';
import DatingCardComp from '../datingCard/datingCard.index';
const datingData = __DatingFakeData.sort(() => Math.random() - 0.5).slice(0, 30);
function LandingServiceContentComp({
  activeIndex,
  services,
  categories,
  houses
}: {
  services?: PaginationType<ServiceType>;
  activeIndex?: ServiceTypeEnum;
  categories?: CategoryType[];
  houses?: PaginationType<HouseType>;
}) {
  const bp = useBP();
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  const [activeSlide, setActiveSlide] = useState(0);
  const isFA = langText.lang === LanguageEnum.fa;
  const [slug, setSlug] = useState<Partial<CategoryType>>();
  const [messageApi, contextHolder] = message.useMessage();
  const [servicesList, setServicesList] = useState<PaginationType<ServiceType> | undefined>(services);
  const [serviceCategories, setServiceCategories] = useState(categories);
  const [houseList, setHouseList] = useState(houses);
  const [tenantList, setTenantList] = useState<PaginationType<TenantType>>();
  const [housingState, setHousingState] = useState<{
    type: 'house' | 'tenant';
    houseActiveSlide: number;
    tenantActiveSlide: number;
  }>({
    type: 'house',
    houseActiveSlide: 0,
    tenantActiveSlide: 0
  });
  const sliderLayout = [{
    max_width: BPEnum.SM,
    perView: 1.3,
    spacing: 12
  }, {
    max_width: BPEnum.MD,
    perView: 2.3,
    spacing: 12
  }, {
    max_width: BPEnum.LG,
    perView: 2.3
  }, {
    max_width: BPEnum.XL,
    perView: 2.9
  }, {
    max_width: BPEnum.XXL,
    perView: 3.9,
    origin: 0.04
  }];
  useEffect(() => {
    // start over slider
    setActiveSlide(0);

    // get slider data
    if (activeIndex === ServiceTypeEnum.offer) {
      if (!serviceCategories) RestAPI.get<PaginationType<CategoryType>>(API.categories.list, {
        lang: langText.lang,
        page_size: 100
      }).then(res => setServiceCategories(res.data.data));
      if (!servicesList) RestAPI.get<PaginationType<ServiceType>>(API.services.index, {
        lang,
        page_size: 20,
        order: 'most_priority',
        brand__is_active: true,
        is_active: true
      }).then(res => setServicesList(res.data));
    } else if (activeIndex === ServiceTypeEnum.housing) {
      if (housingState.type === 'house' && !houseList) RestAPI.get<PaginationType<HouseType>>(API.housing.houseList, {
        page_size: 20
      }).then(res => setHouseList(res.data));
      if (housingState.type === 'tenant' && !tenantList) RestAPI.get<PaginationType<HouseType>>(API.housing.tenantList, {
        page_size: 20
      }).then(res => setTenantList(res.data));
    }
  }, [activeIndex, housingState.type]);
  useEffect(() => {
    if (slug === undefined) setServicesList(services);else {
      setServicesList(undefined);
      RestAPI.get<PaginationType<ServiceType>>(API.services.index, {
        lang: langText.lang,
        category_and_descendants: slug?.slug ?? '',
        order: 'most_priority',
        brand__is_active: true,
        is_active: true,
        page_size: 20
      }).then(res => setServicesList(res.data)).catch(() => messageApi.open({
        type: 'error',
        content: lang.errorMessage
      }));
    }
  }, [slug]);
  const slider2Btn = (actionTitle: string, actionLoading: boolean, actionPath: string, sliderIndex: number, sliderLength: number, isHousing?: boolean) => {
    const housingKey = housingState.type === 'house' ? 'houseActiveSlide' : 'tenantActiveSlide';
    return <div className={`${styles['arrow-btn']} center-content mt-4 gap-32`} data-sentry-component="slider2Btn" data-sentry-source-file="serviceContent.index.tsx">
                <span onClick={() => isHousing ? setHousingState(e => ({
        ...e,
        [housingKey]: isFA ? sliderIndex > 0 ? sliderIndex - 1 : 0 : sliderIndex < sliderLength - 1 ? sliderIndex + 1 : sliderLength - 1
      })) : setActiveSlide(isFA ? prev => prev > 0 ? prev - 1 : 0 : prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1)} className={`pointer center-content d-pre-md-none ${isFA && sliderIndex === 0 || !sliderLength ? styles['disabled'] : langText.lang === LanguageEnum.en && sliderIndex === sliderLength - 1 ? styles['disabled'] : ''}`}>
                    {isFA ? <TbArrowRight color="#ffffff" /> : <TbArrowLeft color="#ffffff" />}
                </span>
                <AppLinkComp href={actionPath} data-sentry-element="AppLinkComp" data-sentry-source-file="serviceContent.index.tsx">
                    <Button loading={actionLoading} className="ph-5-i" data-sentry-element="Button" data-sentry-source-file="serviceContent.index.tsx">
                        {actionTitle}
                    </Button>
                </AppLinkComp>
                <span onClick={() => isHousing ? setHousingState(e => ({
        ...e,
        [housingKey]: isFA ? sliderIndex < sliderLength - 1 ? sliderIndex + 1 : sliderLength - 1 : sliderIndex > 0 ? sliderIndex - 1 : 0
      })) : setActiveSlide(isFA ? prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1 : prev => prev > 0 ? prev - 1 : 0)} className={`pointer center-content d-pre-md-none ${!sliderLength || isFA && sliderIndex === sliderLength - 1 ? styles['disabled'] : langText.lang === LanguageEnum.en && sliderIndex === 0 ? styles['disabled'] : ''}`}>
                    {isFA ? <TbArrowLeft color="#ffffff" /> : <TbArrowRight color="#ffffff" />}
                </span>
            </div>;
  };
  return <div className={`${styles['content-layout']}`} data-sentry-component="LandingServiceContentComp" data-sentry-source-file="serviceContent.index.tsx">
            {contextHolder}
            {activeIndex === ServiceTypeEnum.offer ? <div className={`${styles['slider']}`}>
                    {serviceCategories ? <CategoryListComp data={serviceCategories} onCategoryChange={item => setSlug(item)} slug={slug} /> : null}
                    {servicesList ? <SliderWrapperComp items={servicesList.data} child={ServiceCardComp} noLoop origin={0.03} loadingHeight={315} className={`pt-4 ${styles['slider-content']}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={activeSlide} onSlideChange={e => setActiveSlide(e)} layout={sliderLayout} /> : <div className={`${styles['loading-box']} center-content w-100`}>
                            <Spin />
                        </div>}
                    {slider2Btn(servicesList && servicesList.count === 0 ? bp?.MD_LTE ? lang.seeAllService : lang.seeAllService : bp?.MD_LTE ? lang.seeAll : lang.see + ' ' + (servicesList?.count ?? '') + ' ' + lang.buy2, !servicesList, servicesList && servicesList.count === 0 ? PATH(langText.lang).services.index() : PATH(langText.lang).services.index({
        category: slug?.slug ?? ''
      }), activeSlide, servicesList?.data.length ?? 0)}
                </div> : activeIndex === ServiceTypeEnum.dating ? <div className={`${styles['slider']}`}>
                    <SliderWrapperComp items={datingData} child={DatingCardComp} noLoop origin={0.03} loadingHeight={315} className={`pt-4 ${styles['slider-content']}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={activeSlide} onSlideChange={e => setActiveSlide(e)} layout={sliderLayout} />
                    {slider2Btn(lang.personalizeDating, false, PATH(langText.lang).panel.dating(), activeSlide, datingData.length)}
                </div> : activeIndex === ServiceTypeEnum.housing ? <div className={`${styles['housing']}`}>
                    <div className={`ph-4 gap-16 ${styles['btn']}`}>
                        <div onClick={() => setHousingState(e => ({
          ...e,
          type: 'house'
        }))} className={`flex-1 center-content ${housingState.type === 'house' ? styles['active'] : ''}`}>
                            {lang.houseSearch}
                        </div>
                        <div onClick={() => setHousingState(e => ({
          ...e,
          type: 'tenant'
        }))} className={`flex-1 center-content ${housingState.type === 'tenant' ? styles['active'] : ''}`}>
                            {lang.tenantSearch}
                        </div>
                    </div>
                    {housingState.type === 'house' ? houseList ? <SliderWrapperComp items={houseList?.data} child={HouseCardComp} noLoop origin={0.03} loadingHeight={425} className={`pt-4 ${housingState.type === 'house' ? '' : 'd-none'}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={housingState.houseActiveSlide} onSlideChange={e => setHousingState(prev => ({
        ...prev,
        houseActiveSlide: e
      }))} layout={sliderLayout} /> : <div className={`${styles['houses-loading-box']} center-content w-100`}>
                                <Spin />
                            </div> : null}
                    {housingState.type === 'tenant' ? tenantList ? <SliderWrapperComp items={tenantList.data} child={RoommateCardComp} noLoop origin={0.03} loadingHeight={275} className={`pt-4 ${housingState.type === 'tenant' ? '' : 'd-none'}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={housingState.tenantActiveSlide} onSlideChange={e => setHousingState(prev => ({
        ...prev,
        tenantActiveSlide: e
      }))} layout={sliderLayout} /> : <div className={`${styles['tenants-loading-box']} center-content w-100`}>
                                <Spin />
                            </div> : null}
                    {slider2Btn(housingState.type === 'house' ? lang.moreHouseSearch : lang.moreTenantSearch, false, housingState.type === 'house' ? PATH(langText.lang).housing.houses() : PATH(langText.lang).housing.roommates(), housingState.type === 'house' ? housingState.houseActiveSlide : housingState.tenantActiveSlide, housingState.type === 'house' ? houseList?.count ?? 0 : tenantList?.count ?? 0, true)}
                </div> : <div className={`${styles['coming-soon']} center-content`}>
                    <div className="ph-3">
                        <Image src={isFA ? csSVG : csEnSVG} alt="coming soon" width={570} height={370} />
                    </div>
                </div>}
        </div>;
}
export default LandingServiceContentComp;