import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/assets/images/logo-typo-fa.svg");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/appLink/appLink.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/base/base.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/bottomNavigation/bottomNavigation.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/clientRender/clientRender.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/eventCard/eventCard.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/gallery/gallery.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/housesCard/housesCard.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/locationSelect/locationSelect.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/primaryServiceCard/primaryServiceCard.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/reviewCard/reviewCard.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/roommatesCard/roommatesCard.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/serviceFilter/serviceFilter.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/serviceNavigation/serviceNavigation.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/slider/slider.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/successOfferBuyModal/successModal.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/tehrantoFeatures/tehrantoFeatures.index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/storage.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useAsyncClick.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useBP.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useEventListener.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useOnBlur.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useRealTimeState.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/tehranto/landing2/landing.index.tsx")