'use client';

import { BestsBrandsType } from '@/types';
import styles from './bestCard.module.scss';
import { Collapse, CollapseProps } from 'antd';
import Image from 'next/image';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import { COLORS } from '@/utilities';
import { GiTrophyCup } from 'react-icons/gi';
import { MouseEvent, useContext, useState } from 'react';
import { GlobalContext } from '@/context';
function BestCardComp({
  data,
  best
}: {
  data: BestsBrandsType;
  best?: boolean;
}) {
  const [vote, setVote] = useState({
    up: false,
    down: false
  });
  const voteHandler = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, type: 'down' | 'up') => {
    e.stopPropagation();
    if (type === 'up') setVote({
      up: true,
      down: false
    });
    if (type === 'down') setVote({
      up: false,
      down: true
    });
    if (type === 'up' && vote.up) setVote({
      up: false,
      down: false
    });
    if (type === 'down' && vote.down) setVote({
      up: false,
      down: false
    });
  };
  const langText = useContext(GlobalContext).langText.bests;
  const CollapseHeaderContent = () => {
    return <div className={`${styles['collapse-header-content']} center-content-y flex-between`} data-sentry-component="CollapseHeaderContent" data-sentry-source-file="bestCard.index.tsx">
                <div className={`${styles['right-section']} center-content-y`}>
                    {data.ads ? <div className={`${styles['ad']} center-content d-pre-md-none`}>
                            <p>Ad</p>
                        </div> : best ? <div className={`${styles['best']} center-content d-pre-md-none`}>
                            <GiTrophyCup size={18} color="#fff" />
                        </div> : <div className={`${styles['index']} center-content d-pre-md-none`}>
                            <p>{data.id}</p>
                        </div>}
                    <div className={`${styles['image']}`}>
                        <Image src={data.image.file} width={50} height={50} alt={data.title} data-sentry-element="Image" data-sentry-source-file="bestCard.index.tsx" />
                    </div>
                    <div className={`${styles['title']} center-content mh-1`}>
                        <p className="t-p1 t-500 t-dark1 center-content">
                            {data.title} <MdOutlineKeyboardArrowDown size={16} className="mh-1-i" data-sentry-element="MdOutlineKeyboardArrowDown" data-sentry-source-file="bestCard.index.tsx" />
                        </p>
                    </div>
                </div>
                <div className={`${styles['left-section']} center-content`}>
                    <div onClick={e => voteHandler(e, 'down')} className={`${styles['down']} ${vote.down ? styles['down-active'] : ''} center-content`}>
                        <p className="t-red t-500">{vote.down ? data.down_vote + 1 : data.down_vote}</p>{' '}
                        <GoArrowDown size={20} color={vote.down ? '#fff' : COLORS.red} data-sentry-element="GoArrowDown" data-sentry-source-file="bestCard.index.tsx" />
                    </div>
                    <div onClick={e => voteHandler(e, 'up')} className={`${styles['up']} ${vote.up ? styles['up-active'] : ''} center-content`}>
                        <p className="t-primary t-500">{vote.up ? data.up_vote + 1 : data.up_vote}</p>{' '}
                        <GoArrowUp size={20} color={vote.up ? '#fff' : COLORS.primary} data-sentry-element="GoArrowUp" data-sentry-source-file="bestCard.index.tsx" />
                    </div>
                </div>
            </div>;
  };
  const items: CollapseProps['items'] = [{
    key: data.id,
    label: <CollapseHeaderContent />,
    children: <p>{langText.lorem}</p>
  }];
  return <Collapse className={`best-brands-collapse ${best ? 'best-brands' : ''} ${best ? styles['best-brand-radius'] : ''}`} items={items} expandIcon={() => null} data-sentry-element="Collapse" data-sentry-component="BestCardComp" data-sentry-source-file="bestCard.index.tsx" />;
}
export default BestCardComp;